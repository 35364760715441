import React from "react"
import { Container, Row, Col } from "react-bootstrap"

import Section from "./structural/section"
import Form from "./paragraph/form"

export default () => {
  let id = "ID_cta"
  let background = { format: "gradient", data: "light-blue" }
  return (
    <Section id={id} background={background}>
      <Container>
        <Row>
          <Col xs={12} md={6}>
            <h2>If you’ve got questions, we’ve got answers.</h2>
            <p>
              Drop your details into the form and hit submit. We'll do our best
              to respond to all enquiries within 24 hours.
            </p>
            <p>
              Want to see our products and services in action? We can provide a
              live demonstration anytime, anywhere. Click here to request a
              demo.
            </p>
          </Col>
          <Col xs={12} md={6}>
            <Form
              props={{
                field_form_id: "6a80150b-ce4e-ea11-a812-000d3a6a992a",
                field_form_id_2: "dU_sCyPFOPMN5CphBDyC1R4IOlazFHG054YbQcINwJQs",
              }}
            />
          </Col>
        </Row>
      </Container>
    </Section>
  )
}
