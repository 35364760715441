import React from "react"
import { graphql } from "gatsby"
import CallToAction from "../../components/call-to-action"

import { Col, Container, Row } from "react-bootstrap"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../../layouts/default"
import "../industry.scss"
import SEO from "../../components/seo"
import Section from "../../components/structural/section"

export default ({ data }) => {
  console.log(data)

  const industry = data.allNodeIndustry.edges[0].node

  const title = industry.field_title_override
  const excerpt = industry.field_excerpt
  const mediaImage =
    industry.relationships.field_media_image.relationships.field_media_image
  const mediaImageAlt =
    industry.relationships.field_media_image.field_media_image.alt
  const portraitImage =
    industry.relationships.field_media_portrait_image.relationships
      .field_media_image
  const portraitImageAlt =
    industry.relationships.field_media_portrait_image.field_media_image.alt
  const industrySwp = industry.relationships.field_safe_work_practice
  const industryChallenge = industry.relationships.field_challenges
  const industryChallengeMediaAlt =
    industryChallenge.relationships.field_media_challenges.field_media_image
  const industryBenefit = industry.relationships.field_benefits
  const industryBenefitVal = industryBenefit.relationships.field_benefits
  const industryquote = industry.relationships.field_get_a_quote
  const industryFeature = industry.relationships.field_features
  const industryFeatureVal = industryFeature.relationships.field_features

  const seoImage = mediaImage.localFile.publicURL

  return (
    <Layout>
      <SEO title={title} description={excerpt} image={seoImage}/>
      <IndustryHeaders
        title={title}
        excerpt={excerpt}
        mediaImage={mediaImage}
        mediaImageAlt={mediaImageAlt}
        portraitImage={portraitImage}
        portraitImageAlt={portraitImageAlt}
      />
      <IndustrySwps industrySwp={industrySwp}/>
      <IndustryChallenges
        industryChallenge={industryChallenge}
        image={
          industry.relationships.field_challenges.relationships
            .field_media_challenges.relationships.field_media_image.localFile
        }
        industryChallengeMediaAlt={industryChallengeMediaAlt}
      />
      <IndustryBenefits
        industryBenefit={industryBenefit}
        industryBenefitVal={industryBenefitVal}
      />
      <IndustryGetQuote industryquote={industryquote}/>
      <IndustryFeatures
        industryFeature={industryFeature}
        industryFeatureVal={industryFeatureVal}
      />
      <CallToAction/>
    </Layout>
  )
}

const IndustryHeaders = props => {
  let id = "ID_headers"
  let background = { format: undefined }
  let image_portrait = props.portraitImage
    ? props.portraitImage.localFile
    : undefined

  return (
    <Section id={id} background={background} style={{ padding: 0 }}>
      <Container
        className={"d-flex c_PageHeader dark"}
        fluid={true}
        style={{
          minHeight: "calc(100vh - 96px)",
          position: "relative",
          overflow: "hidden",
        }}
      >
        {/* Landscape Image */}
        <GatsbyImage
          image={props.mediaImage.localFile.childImageSharp.gatsbyImageData}
          style={{
            position: "absolute",
            minWidth: "100%",
            minHeight: "100%",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
          alt={props.mediaImageAlt}
          className={image_portrait ? "d-none d-lg-block" : ""} />
        {/* Portrait Image */}
        {image_portrait && (
          <GatsbyImage
            image={image_portrait.childImageSharp.gatsbyImageData}
            style={{
              position: "absolute",
              minWidth: "100%",
              minHeight: "100%",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
            alt={props.portraitImageAlt}
            className={"d-lg-none"} />
        )}
        {/* Content */}

        <Container className={"m-auto"}>
          <Row className={"justify-content-start"}>
            <Col xs={12} md={8} xl={6}>
              <h1>{props.title}</h1>
              <p>{props.excerpt}</p>

              <AnchorLink
                to="/industries/education#ID_benefits"
                title="See the benefits"
                className="btn btn-primary btn-md"
                stripHash
              />

              <AnchorLink
                to="/get-quote"
                title="Get a quote"
                className="btn btn-secondary btn-md"
              />
            </Col>
          </Row>
        </Container>
      </Container>
    </Section>
  );
}
const IndustrySwps = props => {
  let id = "ID_practices"
  let background = { format: undefined }
  let SwpTitle = props.industrySwp.field_title_safe_work_practice
  let SwpIcon =
    props.industrySwp.relationships.field_icon_safe_work_practice.relationships
      .field_media_image
  let SwpDesc = props.industrySwp.field_desc_safe_work_practice

  return (
    <Section id={id} background={background}>
      <Container>
        <Row className={"justify-content-center"}>
          <Col xs={12} md={10} xl={8}>
            <img
              src={SwpIcon.localFile.publicURL}
              alt={SwpTitle}
              style={{ height: "120px", marginBottom: "20px" }}
            />
            <h2>{SwpTitle}</h2>
            <p>{SwpDesc}</p>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}
const IndustryChallenges = props => {
  let id = "ID_challenges"
  let background = { format: "gradient", data: "light-blue" }

  return (
    <Section id={id} background={background}>
      <Container>
        <Row className={"justify-content-center"}>
          <Col xs={12} lg={6}>
            <GatsbyImage
              image={{ ...props.image.childImageSharp.gatsbyImageData }}
              imgStyle={{ objectFit: "cover" }}
              className={"d-block w-100"}
              style={{ marginTop: "15px" }}
              alt={props.industryChallengeMediaAlt.alt} />
          </Col>
          <Col xs={12} lg={6}>
            <p
              dangerouslySetInnerHTML={{
                __html:
                props.industryChallenge.field_description_challenges.value,
              }}
            ></p>
          </Col>
        </Row>
      </Container>
    </Section>
  );
}
const IndustryBenefits = props => {
  let id = "ID_benefits"
  let BenefitHeadingText = props.industryBenefit.field_title

  let benefitvalues = props.industryBenefitVal
  return (
    <Section id={id}>
      <Container>
        <Row className={"justify-content-center"}>
          <Col xs={12} md={10} xl={10}>
            <h2>{BenefitHeadingText}</h2>
          </Col>
        </Row>
        <Row className={"justify-content-center"}>
          {benefitvalues.map((item, key) => {
            return (
              <Col key={key} xs={12} md={6} xl={3}>
                <GatsbyImage
                  image={item.relationships.field_benefit_image.relationships
                    .field_media_image.localFile.childImageSharp.gatsbyImageData}
                  alt={
                    item.relationships.field_benefit_image.field_media_image.alt
                  } />

                <h3>{item.field_title}</h3>
                <p> {item.field_benefit_description}</p>
              </Col>
            );
          })}
        </Row>
      </Container>
    </Section>
  );
}

const IndustryGetQuote = props => {
  let id = "ID_quote"

  let background = { format: "gradient", data: "light-blue" }
  let QuoteDesc = props.industryquote.field_desc_get_a_quote

  return (
    <Section id={id} background={background}>
      <Container fluid="true">
        <Row className={"justify-content-center"}>
          <Col xs={12} md={10} xl={8}>
            <div
              dangerouslySetInnerHTML={{
                __html: QuoteDesc.value,
              }}
            />
            <AnchorLink
              to="/get-quote"
              title="Get a Quote"
              className="btn btn-primary btn-md"
            />
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

const IndustryFeatures = props => {
  let id = "ID_features"
  let featureHeadingText = props.industryFeature.field_title

  let featureValues = props.industryFeatureVal
  return (
    <Section id={id}>
      <Container>
        <Row className={"justify-content-center"}>
          <Col xs={12} md={10} xl={10}>
            <h2 className={`text-center`}>{featureHeadingText}</h2>
          </Col>
        </Row>
        <Row className={"justify-content-center"}>
          {featureValues.map((item, key) => {
            return (
              <Col key={key} xs={12} md={6} xl={4}>
                <img
                  src={
                    item.relationships.field_feature_icon.relationships
                      .field_media_image.localFile.publicURL
                  }
                  alt={item.field_title}
                  style={{ height: "50px", margin: "10px" }}
                />

                <h3>{item.field_title}</h3>
                <p> {item.field_feature_description}</p>
              </Col>
            )
          })}
        </Row>
      </Container>
    </Section>
  )
}

export const query = graphql`query ($id: String!) {
  allNodeIndustry(filter: {id: {eq: $id}}) {
    edges {
      node {
        drupal_id
        field_title_override
        field_excerpt
        path {
          alias
        }
        relationships {
          field_media_image {
            field_media_image {
              alt
              title
            }
            relationships {
              field_media_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                  publicURL
                }
              }
            }
          }
          field_media_portrait_image {
            field_media_image {
              alt
              title
            }
            relationships {
              field_media_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                }
              }
            }
          }
          field_safe_work_practice {
            field_desc_safe_work_practice
            field_title_safe_work_practice
            relationships {
              field_icon_safe_work_practice {
                relationships {
                  field_media_image {
                    localFile {
                      publicURL
                    }
                  }
                }
              }
            }
          }
          field_challenges {
            field_description_challenges {
              processed
              value
            }
            relationships {
              field_media_challenges {
                field_media_image {
                  alt
                  title
                }
                relationships {
                  field_media_image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                      }
                    }
                  }
                }
              }
            }
          }
          field_benefits {
            field_title
            relationships {
              field_benefits {
                field_title
                field_benefit_description
                relationships {
                  field_benefit_image {
                    field_media_image {
                      alt
                      title
                    }
                    relationships {
                      field_media_image {
                        localFile {
                          childImageSharp {
                            gatsbyImageData(layout: FULL_WIDTH)
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          field_get_a_quote {
            field_desc_get_a_quote {
              processed
              value
            }
          }
          field_features {
            field_title
            relationships {
              field_features {
                field_feature_description
                field_title
                relationships {
                  field_feature_icon {
                    field_media_image {
                      height
                      title
                      alt
                    }
                    relationships {
                      field_media_image {
                        localFile {
                          publicURL
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          field_call_to_action {
            field_desc_call_to_action {
              processed
            }
          }
        }
      }
    }
  }
}
`
